import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ZhCnLocal from "element-ui/lib/locale/lang/zh-CN";

Vue.use(ElementUI, {
  size: 'small',
  locale: ZhCnLocal
});

// moment 时间处理
import moment from "moment";

// 全局方法挂载
Vue.prototype.$moment = moment


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


