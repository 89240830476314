import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "Index",
            component: () => import('@/views/index'),
        },
        {
            path: '/en',
            name: "English",
            component: () => import('@/views/english/index'),
        },
    ],
});

export default router;